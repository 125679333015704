import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { JmapService } from '@services/jmap.service';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager } from '@services/managers/popup.manager';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'jmap',
  templateUrl: './jmap.component.html',
  styleUrls: ['./jmap.component.scss']
})
export class JmapComponent implements OnInit, OnDestroy {

  @ViewChild('jmap') public jmap: ElementRef<HTMLIFrameElement>;

  public jmapPanelContent: SafeResourceUrl;

  protected destroy$ = new Subject<void>();

  private jmapService: JmapService = inject(JmapService);
  private appManager: AppManager = inject(AppManager);
  private sanitizer: DomSanitizer = inject(DomSanitizer);
  private translate: TranslateService = inject(TranslateService);
  private popupManager: PopupManager = inject(PopupManager);

  constructor() {
  }

  /**
   * Init and load jMap NG app
   */
  public ngOnInit(): void {
    this.jmapService.getSessionId().pipe(
      takeUntil(this.destroy$),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 404) {
          return EMPTY;
        } else {
          throw err;
        }
      })
    ).subscribe({
      next: (data) => {
        const projectId = this.appManager.currentOrganization.properties['jmapProjectId'];
        const serverUrl = this.appManager.currentOrganization.properties['jmapServerUrl'];

        const jmapUrl = `${environment.scripts.jmapUrl}/?ngProjectId=${projectId}&ngToken=${data['token']}&serverUrl=${serverUrl}`;
        this.jmapPanelContent = this.sanitizer.bypassSecurityTrustResourceUrl(jmapUrl);
      },
      error: () => {
        this.popupManager.showOkPopup({
          dialogTitle: this.translate.instant('TITLE.ERROR_LOAD_JMAP'),
          dialogMessage: this.translate.instant('MESSAGE.ERROR_LOAD_JMAP'),
          type: 'error'
        });
        throw Error;
      },
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
