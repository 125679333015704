import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { JMapAuthDetails } from '@app/core/model/entities/external-api/jmap-auth-details';
import { JmapCredentialsInput } from '@app/core/model/inputs/jmap-credentials-input';
import { environment } from '@env/environment';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class JmapService {

  private apiService = inject(ApiService);
  private httpClient = inject(HttpClient);
  private appManager = inject(AppManager);

  constructor() {}

  /**
   * get jMap session ID
   * @return jMap sessionId
   */
  public getSessionId(): Observable<Object> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const uri = environment.backend.baseUrl + environment.backend.jmap.endpoint;
    const body = {
      'organizationId': this.appManager.currentOrganization.id
    };

    return this.httpClient.post(uri, body, {headers, responseType: 'json'});
  }


  /**
   * Get an Organization's JMap credentials.
   * @param organizationId Organization's ID.
   * @return Observable that emits the Organization's JMap credentials.
   */
  public getOrganizationJMapCredentials(organizationId: string): Observable<JMapAuthDetails> {
    const query = gql`
      query JMapCredentials($organizationId: String!) {
        organizationJmapCredentials(organizationId: $organizationId) {
          url
          projectId
          username
        }
      }
    `;
    const variables = {organizationId};
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(JMapAuthDetails, data['organizationJmapCredentials'])));
  }

  /**
   * API call to update the Organization's JMap credentials.
   * @param organizationId the Organization ID
   * @param jmapCredentialsInput the new jmap credentials
   * @return the updated Organization
   */
  public upsertOrganizationJmapCredentials(
    organizationId: string,
    jmapCredentialsInput: JmapCredentialsInput
  ): Observable<JMapAuthDetails> {
    const mutation = gql`
      mutation UpsertOrganizationJmapCredentials($organizationId: String!, $jmapCredentialsInput: JmapCredentialsInput!) {
        upsertOrganizationJmapCredentials(organizationId: $organizationId, jmapCredentialsInput: $jmapCredentialsInput) {
          id
          url
          projectId
          username
        }
      }
    `;

    const variables = {
      organizationId: organizationId,
      jmapCredentialsInput: jmapCredentialsInput
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => plainToInstance(JMapAuthDetails, data['upsertOrganizationJmapCredentials']))
    );
  }

  /**
   * Open jMap in fullscreen mode
   * @param iframe The iframe wrapping the jMap iframe
   */
  public toggleFullScreen(iframe: HTMLIFrameElement): void {
    iframe.requestFullscreen().catch(console.error);
  }

}
