import { inject, Injectable } from '@angular/core';
import { CartovistaParameters } from '@app/core/model/entities/external-api/cartovista-parameters';
import { CartovistaParametersInput } from '@app/core/model/inputs/cartovista-parameters-input';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CartovistaService {

  private apiService = inject(ApiService);
  private appManager: AppManager = inject(AppManager);

  constructor() {}

  /**
   * Fetches the organization's Cartovista parameters
   */
  public getCartovistaParameters(organizationId: string): Observable<CartovistaParameters> {
    const query = gql`
      query CartovistaParameters($organizationId: String!) {
        cartovistaParameters(organizationId: $organizationId) {
          id
          serverUrl
          tenantCode
          mapId
          mapName
          apiKey
          isApiKeySet
        }
      }
    `;

    const variables = {
      organizationId
    };
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(CartovistaParameters, data['cartovistaParameters'])));
  }

  /**
   * API call to update the Organization's JMap credentials.
   * @param organizationId the Organization ID
   * @param parametersInput the new jmap credentials
   * @return the updated Organization
   */
  public upsertOrganizationCartovistaParameters(
    organizationId: string,
    parametersInput: CartovistaParametersInput
  ): Observable<CartovistaParameters> {
    const mutation = gql`
      mutation UpsertOrganizationCartovistaParameters($organizationId: String!, $parametersInput: CartovistaParametersInput!) {
        upsertOrganizationCartovistaParameters(organizationId: $organizationId, cartovistaParametersInput: $parametersInput) {
          id
          serverUrl
          tenantCode
          mapId
          mapName
          isApiKeySet
        }
      }
    `;

    const variables = {
      organizationId: organizationId,
      parametersInput: parametersInput
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => plainToInstance(CartovistaParameters, data['upsertOrganizationCartovistaParameters'])));
  }

  public refreshData(): Observable<boolean> {
    const mutation = gql`
      mutation RefreshCartovistaMapData($organizationId: String!) {
        refreshCartovistaData(organizationId: $organizationId)
      }
    `;

    const variables = {
      organizationId: this.appManager.currentOrganization.id
    };
    return this.apiService.mutate({mutation, variables})
      .pipe(switchMap(data => EMPTY));
  }

  /**
   * Open jMap in fullscreen mode
   * @param iframe The iframe wrapping the jMap iframe
   */
  public toggleFullScreen(iframe: HTMLIFrameElement): void {
    iframe.requestFullscreen().catch(console.error);
  }

}
