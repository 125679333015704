@if (cartovistaPanelContent) {
  <div id="cartovista-div">
    <iframe #cartovista id="cartovista" [src]="cartovistaPanelContent" [title]="'TITLE.CARTOVISTA' | translate"></iframe>
  </div>
  <restricted-icon-button (onClick)="refreshData()" [class]="'mdi mdi-refresh sm'"
                          [matTooltip]="'TOOLTIP.REFRESH_GEOSPATIAL_DATA' | translate"
                          [permissions]="[Permission.MANAGE_USER]"
                          [disabled]="state === 'pending'"
                          [spin]="state === 'pending'"
                          [preconditions]="state === 'none'">
  </restricted-icon-button>
} @else {
  <spinner></spinner>
}
