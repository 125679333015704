import { Entity } from '@app/core/model/entities/entity';

export class CartovistaParameters extends Entity {
  public serverUrl: string;
  public tenantCode: string;
  public mapId: string;
  public mapName: string;
  public apiKey: string;
  public isApiKeySet: boolean;
}
