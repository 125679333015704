import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { CartovistaParameters } from '@app/core/model/entities/external-api/cartovista-parameters';
import { TranslateService } from '@ngx-translate/core';
import { CartovistaService } from '@services/cartovista.service';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cartovista',
  templateUrl: './cartovista.component.html',
  styleUrl: './cartovista.component.scss'
})
export class CartovistaComponent implements OnInit, OnDestroy {

  protected readonly Permission = PermissionEnum;

  @ViewChild('cartovista') public cartovista: ElementRef<HTMLIFrameElement>;

  private cartovistaParameters: CartovistaParameters;
  public cartovistaPanelContent: SafeResourceUrl;
  public state: 'none' | 'pending' = 'none';

  protected destroy$ = new Subject<void>();

  private cartovistaService: CartovistaService = inject(CartovistaService);
  private appManager: AppManager = inject(AppManager);
  private sanitizer: DomSanitizer = inject(DomSanitizer);
  private translate: TranslateService = inject(TranslateService);
  private snackbarManager: SnackbarManager = inject(SnackbarManager);
  private popupManager: PopupManager = inject(PopupManager);

  constructor() {
  }

  /**
   * Init and load Cartovista embed
   */
  public ngOnInit(): void {
    this.cartovistaService.getCartovistaParameters(this.appManager.currentOrganization.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (data) => {
        this.cartovistaParameters = data;
      },
      error: () => {
        this.popupManager.showOkPopup({
          dialogTitle: this.translate.instant('TITLE.ERROR_LOAD_CARTOVISTA'),
          dialogMessage: this.translate.instant('MESSAGE.ERROR_LOAD_CARTOVISTA'),
          type: 'error'
        });
        throw Error;
      },
      complete: () => {
        this.updateMapUrl();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public refreshData(): void {
    this.cartovistaService.refreshData().subscribe({
      complete: () => {
        this.updateMapUrl();
        this.snackbarManager.showActionSnackbar(this.translate.instant('MESSAGE.REFRESH_CARTOVISTA'));
      },
      error: () => {
        this.popupManager.showOkPopup({
          dialogTitle: this.translate.instant('TITLE.ERROR_REFRESH_CARTOVISTA'),
          dialogMessage: this.translate.instant('MESSAGE.ERROR_REFRESH_CARTOVISTA'),
          type: 'error'
        });
      }
    });
  }

  private updateMapUrl(): void {
    const mapUrl = `${this.cartovistaParameters.serverUrl}/${this.cartovistaParameters.tenantCode}/maps/${this.cartovistaParameters.mapId}/${this.cartovistaParameters.mapName}?embed&notopbar&nosidebar&apiKey=${this.cartovistaParameters.apiKey}`;
    this.cartovistaPanelContent = this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);
  }
}
